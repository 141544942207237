import { useQuery } from '@apollo/client';
import { PAGINATED_FILES } from '../../shared/Services/Graphql/Graphql';
import { FileConnection } from '../../shared/interfaces/FileConnection';
import { useState } from 'react';
import { Next_Icon, Previous_Icon } from '../../../assets/svg';

import './file.scss';

export default function Loop_file_general(props: any) {
  const { selectFile, select } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 14;

  const { loading, error, data, refetch } = useQuery<{ filesPaginated: FileConnection }>(
    PAGINATED_FILES,
    {
      variables: {
        page: currentPage,
        limit,
      },
      fetchPolicy: 'network-only',
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Algo deu errado</p>;

  const files = data?.filesPaginated.files;
  const pageInfo = data?.filesPaginated.pageInfo;

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= (pageInfo?.totalPages || 1)) {
      setCurrentPage(newPage);
      refetch({ page: newPage });
    }
  };

  const renderPagination = () => {
    const totalPages = pageInfo?.totalPages || 1;
    const pages: (number | string)[] = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 3);
      const endPage = Math.min(totalPages, currentPage + 3);

      if (startPage > 1) pages.push(1);
      if (startPage > 2) pages.push("...");

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) pages.push("...");
      if (endPage < totalPages) pages.push(totalPages);
    }

    return pages.map((page, index) => (
      <button
        key={index}
        className={`page_number ${page === currentPage ? 'active' : 'normal'}`}
        onClick={() => typeof page === 'number' && handlePageChange(page)}
        disabled={page === "..."}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className="galery_pagination">
      <div className="galery_image">
        {files?.map((f, i) => (
          <div
            className={`image ${select === f.id ? 'select_info' : ''}`}
            key={i}
          >
            <figure onClick={() => selectFile({ id: f.id, url: f.url, name: f.name, width: f.width, height: f.height })}>
              <img src={f.url} alt='' />
            </figure>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          className='next_previous'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {Previous_Icon}
        </button>
        {renderPagination()}
        <button
          className='next_previous'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === pageInfo?.totalPages}
        >
          {Next_Icon}
        </button>
      </div>
    </div>
  );
}
